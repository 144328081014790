<template>
    <div class="Publish">
        <Modal v-if="filetemplate !=null" v-model="showpreview" title="模板内容预览" width="90%" margin-top:10px @on-ok="showpreview=false">
            <div id="previewdiv"  style="margin:0 auto;float:top;margin-top:1px;padding: 5px;width:1000px;background:rgb(233, 238, 234)">
                <div v-html="filetemplate.templatecontent" ></div>
            </div>
        </Modal>
      <h1>编辑模板</h1>
        <Row gutter="10" align="middle">
            <i-col span="18">
                <div id="editdiv" v-if="filetemplate !=null">
                    <h3>模板标题：</h3>
                    <Input v-model="filetemplate.templatename" placeholder="模板标题" style="width: 100%;"></Input>
            
                    <h3>模板内容：</h3>
                    
                    <tinymce ref="editor" v-model="filetemplate.templatecontent" style="margin-top: 20px;height:400px"/>
                    </div>
                    <div id="btnbar">
                        <Button @click="closewindow" type="error" ghost>关闭窗口</Button>
                        <Button @click="clear" type="success" ghost >清空内容</Button>
                        <Button @click="preview" type="warning"  >预览</Button>
                        <Button @click="submmit" type="primary">提交</Button>
                </div>
            </i-col>
            <i-col span="6" >
                <Card style="background: rgb(191, 209, 228);">
                    <p slot="title">
                        <Icon type="ios-film-outline"></Icon>
                        自动生成内容标记制作清单
                    </p>
                    <table class="notetbl" cellspacing="0">
                        <tr>
                            <th>替换内容</th>
                            <th>替换标记</th>
                        </tr>
                        <tr>
                            <td>文件编码中的公司代号：</td>
                            <td>${companycode}</td>
                        </tr>
                        <tr>
                            <td>文件中的公司名称：</td>
                            <td>${companyname}</td>
                        </tr>
                        <tr>
                            <td>文件中版本号：</td>
                            <td>${version}</td>
                        </tr>
                        <tr>                        
                            <td>文件生效日期：</td>
                            <td>${effectdate}</td>
                        </tr>
                        <tr>
                            <td>整车编码中的企业代码：</td>
                            <td>${codeinvin}</td>
                        </tr>
                        <tr>
                            <td>总经理：</td>
                            <td>${ceo}</td>
                        </tr>
                        <tr>
                            <td>生产部长：</td>
                            <td>${productionmanager}</td>
                        </tr>
                        <tr>
                            <td>质量/认证负责人：</td>
                            <td>${qualitymanager}</td>
                        </tr>
                        <tr>
                            <td>设备保养人名字：</td>
                            <td>${maintainername}</td>
                        </tr>
                        <tr>
                            <td>文档编写人：</td>
                            <td>${filewriter}</td>
                        </tr>
                        <tr>
                            <td>文档审核人：</td>
                            <td>${filechecker}</td>
                        </tr>
                        <tr>
                            <td>文档批准人：</td>
                            <td>${fileapprover}</td>
                        </tr>
                    </table>
                </Card>
            </i-col>
        </Row>
      
    </div>

  </template>
  
  <script>
  import tinymce from '@/components/tinymce.vue'
  export default {
    name: "TemplateEdit",
    props: {
    },
    data(){
        return {
            templateid:window.atob(this.$route.query.templateid),
            userlevel:this._self.$root.$store.state.LogonInfo.level,
            companyid:this._self.$root.$store.state.LogonInfo.companyid,
            userCategory:this._self.$root.$store.state.LogonInfo.userCategory,
            filetemplate:null,
            showpreview:false
        }
    },
    components:{
      tinymce
    },
    mounted(){
      //this.refresh();
      this.querytemplate()
    },
    methods:{
        closewindow(){
            window.close()
        },
        // 清空内容
        clear () {
            this.$refs.editor.clear()
        },
        onEditorChange(event) {
            console.log('onEditorChange')
        },
        querytemplate(){
            let posturl
            if(this.userCategory == 'superadmin'){
                posturl = "/api/checkdept/template/public/query"
            }
            else{
                posturl = "/api/checkdept/template/private/query"
            }

            let postdata={
                templateid: this.templateid,
                pageNum: 0,
                pageSize: 10
            }

            this.$Loading.start();
            this.$axios({
                method:'post',
                url:posturl,
                data:postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
                }).then(function(res){
                if(res.data.resultCode ==0){
                    this.$Loading.finish();
                    console.log(res)
                    if(res.data.data.totalnumber == 0){
                        this.$Loading.error();
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                    else{
                        this.filetemplate = res.data.data.data[0]
                        console.log(this.filetemplate)
                    }
                }
                else{
                    this.$Loading.error();
                    this.$Message.error({content:res.data.msg, duration:3})
                }
                }.bind(this));   
        },
        preview(){
            this.showpreview = true
        },
        submmit(){
            console.log(this.filetemplate)
            let posturl
            if(this.userCategory == 'superadmin'){
                posturl = "/api/checkdept/template/public/update"
            }
            else{
                posturl = "/api/checkdept/template/private/update"
            }

            var postdata=JSON.parse(JSON.stringify(this.filetemplate)) 

            for(var p in postdata){
                if(postdata[p] == null){
                    delete postdata[p]
                }
            }
            this.$axios({
                method:'post',
                url:posturl,
                data:postdata,
                headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                transformRequest: function(obj) {
                    var str = [];
                    for(var p in obj){
                        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                    }
                    return str.join("&");
                }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3, closable:true})
                    }
                    else{
                        this.$Message.error({content:res.data.msg, duration:3})
                    }
                }.bind(this));     
        }
    }
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style >
      .Publish {
        margin: 0 auto;
        padding: 5%;
        width:1300px;
      }
    #editdiv {
      margin:0 auto;
      text-align: left
    }
  
    #editdiv h3{
      margin: 15px auto;
    }
  
    #btnbar{
      margin: 20px auto;
      text-align: center;
    }
  
    #btnbar button{
        width:150px;
        margin:10px;
    }
    #editdiv .ql-editor{
      height:400px;
    }
  
    .notetbl{
        font-size: 10px;
        border: 1px solid lightslategrey;
    }
    .notetbl tr td{
        text-align: left;
        border: 1px solid lightslategrey;
    }
  </style>  